document.addEventListener('DOMContentLoaded', function () {
    console.debug("INFO productfinder.js loaded");

    document.querySelectorAll('.js-productfinder').forEach(function (productFinderComponent) {
        var productFinderId = productFinderComponent.getAttribute('id');
        var isAdvanced = productFinderComponent.classList.contains("js-productfinder-advanced");

        // Wait for flickity instance to be ready
        bronson.$$('#' + productFinderId + '.js-fancy-finder')
            .component('fancy-finder').forEach(function (fancyFinder) {
            // Clone prev and next button to remove flickity event handlers
            var oldPreviousButton = productFinderComponent.querySelector(".flickity-prev-next-button.previous");
            oldPreviousButton.disabled = false;
            var newPreviousButton = oldPreviousButton.cloneNode(true);
            oldPreviousButton.parentNode.replaceChild(newPreviousButton, oldPreviousButton);

            var oldNextButton = productFinderComponent.querySelector(".flickity-prev-next-button.next");
            oldNextButton.disabled = false;
            var newNextButton = oldNextButton.cloneNode(true);
            oldNextButton.parentNode.replaceChild(newNextButton, oldNextButton);

            var radioButtons = productFinderComponent.querySelectorAll('.js-pf-radio-button');
            var questions = productFinderComponent.querySelectorAll('.js-productfinder-question');

            // Check radiobuttons initially
            var productFinderConfiguration = getPFConfigById(productFinderId);
            Object.keys(productFinderConfiguration).forEach(function (questionId) {
                var radioButton = document.getElementById(productFinderConfiguration[questionId]["radioButtonId"]);
                if (radioButton) {
                    radioButton.checked = true;
                }
            });

            loadResults(productFinderComponent, true);

            radioButtons.forEach(function (radioButton) {
                radioButton.addEventListener('click', function (event) {
                    // get Configured Mileage for Fuel Savings Calculator
                    var fuelSavingsCalculatorMileage = radioButton.getAttribute("data-mileage");
                    if (fuelSavingsCalculatorMileage != null) {
                        // if there is configured Value, then save it in the LocalStorage in order to be used in the fuel Savings Calculator later
                        window.localStorage.setItem('fuelsavingscalculator_mileage', fuelSavingsCalculatorMileage);
                    }

                    // Check for next question, if there is one, save it in variable
                    var question = radioButton.closest('.js-productfinder-question');
                    var nextQuestionId;
                    if (!isAdvanced) {
                        var nextIndex = getIndexOfChild(question) + 1;
                        var nextQuestion = questions.item(nextIndex);
                        if (nextQuestion) {
                            nextQuestionId = nextQuestion.getAttribute('id');
                        }
                    } else {
                        var nextQuestionRef = radioButton.getAttribute('data-pf-question-ref');
                        if (nextQuestionRef) {
                            nextQuestionId = nextQuestionRef;
                        }
                    }

                    // Update local storage json
                    updatePFSessionStorage(radioButton, nextQuestionId, productFinderComponent);
                    loadResults(productFinderComponent, false);
                });
            });

            // Handle next and previous button clicks
            var nextButton = productFinderComponent.querySelector('.flickity-prev-next-button.next');
            var previousButton = productFinderComponent.querySelector('.flickity-prev-next-button.previous');
            nextButton.addEventListener('click', handlePreviousNextButtonClick.bind(null, event, 'nextQuestionId', productFinderComponent, nextButton));
            previousButton.addEventListener('click', handlePreviousNextButtonClick.bind(null, event, 'previousQuestionId', productFinderComponent, previousButton));

            // Handle reset button click
            var resetButton = productFinderComponent.querySelector('.js-pf-reset');
            resetButton.addEventListener("click", function (event) {
                resetPFSessionStorage(productFinderComponent);
                loadResults(productFinderComponent, false);
            })
        });

    });

    /**
     * Gets the current pf config from session storage and updates the productfinder based on that
     * @param productFinderComponent
     * @param initialLoad
     */
    function loadResults(productFinderComponent, initialLoad) {
        // Init elements
        var productFinderId = productFinderComponent.getAttribute('id');
        var nextButton = productFinderComponent.querySelector('.flickity-prev-next-button.next');
        var previousButton = productFinderComponent.querySelector('.flickity-prev-next-button.previous');
        var flkty = bronson.$$('#' + productFinderId + '.js-fancy-finder')
            .component('fancy-finder').items[0].flickitySlider;

        // Init configurations
        var productFinderConfiguration = getPFConfigById(productFinderId);
        var isAdvanced = productFinderComponent.classList.contains("js-productfinder-advanced");
        var showResultsAtEnd = productFinderComponent.classList.contains("js-pf-show-results-on-the-end");
        var allResults = getAllResults(productFinderComponent);
        // Get the current question
        var questionConfig = productFinderConfiguration[Object.keys(productFinderConfiguration).pop()];

        // If there is no current question, we want to reset the productfinder/load the productfinder unconfigured
        if (!questionConfig) {
            previousButton.style.visibility = "hidden";
            nextButton.style.visibility = "hidden";
            if (showResultsAtEnd) {
                productFinderComponent.classList.add("is-closed");
            }
            filterResults(productFinderComponent, allResults);
            flkty.select(0, false, true);
            return;
        }
        var amountsOfQuestionsAnswered = Object.keys(productFinderConfiguration).length;
        var nextQuestionId = questionConfig["nextQuestionId"];
        var questionId = questionConfig["questionId"];
        var isSimpleAndNotLastQuestion = !isAdvanced && productFinderConfiguration[nextQuestionId];
        var nextQuestion = productFinderComponent.querySelector('#' + nextQuestionId);
        var currentQuestion = productFinderComponent.querySelector('#' + questionId);

        // Show previous button if there is at least 1 answered question, hide otherwise
        if (amountsOfQuestionsAnswered > 0) {
            previousButton.style.visibility = "visible";
            previousButton.style.opacity = "1.0";
        } else {
            previousButton.style.visibility = "hidden";
        }

        // Only in simple mode: Show next button if there is a next question
        // (applies when user chooses different answer on earlier question, the remaining answers will remain checked)
        if (isSimpleAndNotLastQuestion) {
            nextButton.style.visibility = "visible";
            nextButton.style.opacity = "1.0";
        } else {
            nextButton.style.visibility = "hidden";
        }

        if (nextQuestion) {
            var index = getIndexOfChild(nextQuestion);
            flkty.select(index);
        } else if (currentQuestion) {
            // Relevant for page load logic, if the last question was selected it should be the current slide
            var index = getIndexOfChild(currentQuestion);
            flkty.select(index);
        }

        var filteredResults = getFilteredResults(productFinderComponent, allResults);
        filterResults(productFinderComponent, filteredResults);

        // If it's in "show results at end" mode we only want to show results if it's not an initial page load
        // and there is no next question
        if (showResultsAtEnd && !nextQuestionId) {
            productFinderComponent.classList.remove("is-closed");
            if (!initialLoad) {
                var anchor = productFinderComponent.querySelector(".c-fancy-finder__items-wrapper");
                var scroll = new SmoothScroll();
                scroll.animateScroll(anchor);
            }
        } else if (showResultsAtEnd && nextQuestionId) {
            productFinderComponent.classList.add("is-closed");
        }
    }

    /**
     * Get's all possible results
     * @param productFinderComponent
     * @returns {Array}
     */
    function getAllResults(productFinderComponent) {
        // first build an array with all cards
        var allResults = [];
        // get list of existing cards and sections
        productFinderComponent.querySelectorAll('.c-cards-slider__wrapper [data-pf-result-id]').forEach(function (element) {
            allResults.push(element.getAttribute('data-pf-result-id'));
        });
        productFinderComponent.querySelectorAll('.pf-section-container [data-pf-result-id]').forEach(function (element) {
            allResults.push(element.getAttribute('data-pf-result-id'));
        });
        return allResults;
    }

    /**
     * Gets filtered results by comparing them with checked radiobuttons
     * @param productFinderComponent
     * @param allResults
     * @returns {*}
     */
    function getFilteredResults(productFinderComponent, allResults) {
        var filteredResults = allResults;
        //get all checked answers and calculate intersection between existing cards and answers
        productFinderComponent.querySelectorAll('.js-pf-radio-button:checked').forEach(function (element) {
            var refs = element.getAttribute('data-pf-result-ref');
            if (refs) {
                var refsArray = refs.split(',');
                filteredResults = intersect(filteredResults, refsArray);
            }
        });
        return filteredResults;
    }

    /**
     * Does the HTML logic to show and hide the calculated results
     * @param productFinderComponent
     * @param results
     */
    function filterResults(productFinderComponent, results) {
        var productFinderId = productFinderComponent.getAttribute('id');
        var flkty = bronson.$$('#' + productFinderId + ' .js-slider')
            .component('slider').items[0].flickitySlider;
        //first delete all cards from slider and sections
        productFinderComponent.querySelectorAll('.js-slider__element [data-pf-result-id]').forEach(function (element) {
            flkty.remove(element.closest('.c-cards-slider__item.js-slider__item'));
            flkty.resize();
        });
        productFinderComponent.querySelectorAll('.pf-section-container .js-pf-section[data-pf-result-id]').forEach(function (element) {
            element.classList.add('u-hide');
        });
        //end deleting

        //compare intersecten with hidden list and add matching element to slider. Resize flickity after inserting
        var sectionCount = 0;
        for (var j = 0; j < results.length; j++) {
            var result = productFinderComponent.querySelector('[data-pf-result-id=' + results[j] + ']');
            if (result !== null && !result.classList.contains("js-pf-section")) {
                var cardToBeDisplayed = result.closest('.c-cards-slider__item.js-slider__item');
                flkty.insert(cardToBeDisplayed.cloneNode(true), j - sectionCount);
            } else if (result !== null) {
                result.classList.remove('u-hide');
                sectionCount++;
            }
        }
        if (results.length < 1) {
            productFinderComponent.querySelector(".js-pf-noresult").style.display = "block";
        } else {
            productFinderComponent.querySelector(".js-pf-noresult").style.display = "none";
        }
        flkty.resize();
        flkty.select(0, false, true); //workaround to reload slide counter
        triggerEvent(document, 'productFinderLoaded');
    }

    /**
     * Handles logic when a user clicks on previous or next button to show the correct question
     * @param event
     * @param questionKey
     * @param productFinderComponent
     * @param button
     */
    function handlePreviousNextButtonClick(event, questionKey, productFinderComponent, button) {
        // Init configs
        var productFinderId = productFinderComponent.getAttribute('id');
        var productFinderConfiguration = getPFConfigById(productFinderId);
        var flkty = bronson.$$('#' + productFinderId + '.js-fancy-finder')
            .component('fancy-finder').items[0].flickitySlider;
        var newQuestionId;
        var nextButton = productFinderComponent.querySelector('.flickity-prev-next-button.next');
        var previousButton = productFinderComponent.querySelector('.flickity-prev-next-button.previous');
        var selectedQuestion = productFinderComponent.querySelector('.js-productfinder-question.is-selected');
        var selectedQuestionId = selectedQuestion.getAttribute('id');

        // Get previous or next question from current question, if the current question is not answered yet, get
        // id of last answered question
        if (productFinderConfiguration[selectedQuestionId]) {
            newQuestionId = productFinderConfiguration[selectedQuestionId][questionKey];
        } else {
            newQuestionId = productFinderConfiguration[Object.keys(productFinderConfiguration).pop()]["questionId"];
        }

        // Select new slide based on new question id
        var newQuestion = productFinderComponent.querySelector('#' + newQuestionId);
        var newQuestionIndex = getIndexOfChild(newQuestion);
        flkty.select(newQuestionIndex);
        nextButton.style.visibility = "visible";
        previousButton.style.visibility = "visible";

        // If we have more questions in that direction afterwards, show the button, otherwise hide
        if (productFinderConfiguration[newQuestionId] && productFinderConfiguration[newQuestionId][questionKey]) {
            button.style.visibility = "visible";
        } else {
            button.style.visibility = "hidden";
        }
    }

    /**
     * Updates the pf sessions storage, which is used to save the pf configuration
     * @param radioButton
     * @param nextQuestionId
     * @param productFinderComponent
     */
    function updatePFSessionStorage(radioButton, nextQuestionId, productFinderComponent) {
        var productFinderId = productFinderComponent.getAttribute('id');
        var question = radioButton.closest('.js-productfinder-question');
        var questionId = question.getAttribute('id');
        var radioButtonId = radioButton.getAttribute('id');
        var isAdvanced = productFinderComponent.classList.contains("js-productfinder-advanced");

        var productFinderConfigurations = window.sessionStorage.getItem('productFinderConfigurations') ? JSON.parse(window.sessionStorage.getItem('productFinderConfigurations')) : {};
        var productFinderConfiguration = productFinderConfigurations[productFinderId] ? productFinderConfigurations[productFinderId] : {};
        var questionConfig = productFinderConfiguration[questionId];

        // For advanced mode: Reset/delete all answered which come after currently answered question
        if (questionConfig && isAdvanced) {
            var questionIndex = questionConfig["index"];
            Object.keys(productFinderConfiguration).forEach(function (currentQuestionId) {
                var currentQuestionConfig = productFinderConfiguration[currentQuestionId];
                var currentQuestionIndex = currentQuestionConfig["index"];

                // For advanced mode: Reset/delete all answered which come after currently answered question
                if (questionIndex < currentQuestionIndex) {
                    var questionConfigToBeDeleted = productFinderConfiguration[currentQuestionId];
                    var radioButtonId = questionConfigToBeDeleted["radioButtonId"];
                    var radioButton = productFinderComponent.querySelector('#' + radioButtonId);
                    radioButton.checked = false;
                    delete productFinderConfiguration[currentQuestionId];
                }
            });
        }

        // Calculate the index of the new question
        var newIndex = questionConfig != null ? questionConfig["index"] : Object.keys(productFinderConfiguration).length;
        var oldIndex = newIndex - 1;
        var previousQuestionId;

        // If it's not the first answered question, get the previous question id from previous question
        if (oldIndex !== -1) {
            Object.keys(productFinderConfiguration).forEach(function (currentQuestionId) {
                var currentQuestionConfig = productFinderConfiguration[currentQuestionId];
                if (currentQuestionConfig.index === oldIndex) {
                    previousQuestionId = currentQuestionConfig.questionId;
                }
            });
        }

        // Set question config
        productFinderConfiguration[questionId] = {
            "radioButtonId": radioButtonId,
            "questionId": questionId,
            "previousQuestionId": previousQuestionId,
            "nextQuestionId": nextQuestionId,
            "index": newIndex
        };
        productFinderConfigurations[productFinderId] = productFinderConfiguration;
        window.sessionStorage.setItem('productFinderConfigurations', JSON.stringify(productFinderConfigurations))
    }

    /**
     * Reset the pf session storage
     * @param productFinderComponent
     */
    function resetPFSessionStorage(productFinderComponent) {
        var productFinderId = productFinderComponent.getAttribute('id');

        var productFinderConfigurations = window.sessionStorage.getItem('productFinderConfigurations') ? JSON.parse(window.sessionStorage.getItem('productFinderConfigurations')) : {};
        var productFinderConfiguration = productFinderConfigurations[productFinderId] ? productFinderConfigurations[productFinderId] : {};
        Object.keys(productFinderConfiguration).forEach(function (currentQuestionId) {
            var questionConfigToBeDeleted = productFinderConfiguration[currentQuestionId];
            var radioButtonId = questionConfigToBeDeleted["radioButtonId"];
            var radioButton = productFinderComponent.querySelector('#' + radioButtonId);
            radioButton.checked = false;
            delete productFinderConfiguration[currentQuestionId];
        });
        window.sessionStorage.setItem('productFinderConfigurations', JSON.stringify(productFinderConfigurations))
    }

    /**
     * Gets a productfinder configuration by product finder id
     * @param productFinderId
     * @returns {{}}
     */
    function getPFConfigById(productFinderId) {
        var productFinderConfiguration = window.sessionStorage.getItem('productFinderConfigurations') ? JSON.parse(window.sessionStorage.getItem('productFinderConfigurations')) : {};
        return productFinderConfiguration[productFinderId] ? productFinderConfiguration[productFinderId] : {};
    }

    /**
     * Get Index of html element
     * @param child
     * @returns {number}
     */
    function getIndexOfChild(child) {
        var parent = child.parentNode;
        // The equivalent of parent.children.indexOf(child)
        return Array.prototype.indexOf.call(parent.children, child);
    }

    /**
     * Calculates identical elements from 2 arrays
     * @param a
     * @param b
     * @returns {*}
     */
    function intersect(a, b) {
        var t;
        if (b.length > a.length) t = b, b = a, a = t; // indexOf to loop over shorter
        return a.filter(function (e) {
            return b.indexOf(e) > -1;
        });
    }

    function triggerEvent(el, eventName, options) {
        var event;
        if (window.CustomEvent) {
            event = new CustomEvent(eventName, options);
        } else {
            event = document.createEvent('CustomEvent');
            event.initCustomEvent(eventName, true, true, options);
        }
        el.dispatchEvent(event);
    }

});